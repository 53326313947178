const BASE_URL = 'https://clearing-platform-1und1.cp-dev.dev.bitconex.com';
const WS_BASE_URL = BASE_URL.replace(/^http(s)?:\/\//, 'wss://');

export const environment = {
  BASE_URL,
  THEME: 'nova.css',
  CLEARING_API_ENDPOINT: `${BASE_URL!}/partner-api/v1`,
  CLEARING_CUSTOM_API_ENDPOINT: `${BASE_URL!}/clearing-api/v1`,
  MESSAGE_BROKER_URL: `${WS_BASE_URL}/ws`,
  LIGHT_PARTNER_API_PATH: '/light',
  PARTNERSHIP_APP_URL: 'https://1und1.cp-gui.cp-dev.dev.bitconex.com/partnership/',
  ITU_CODE: 'DEU.1UND1',
  KEYCLOAK: {
    URL: 'https://keycloak.cp-dev.dev.bitconex.com',
    REALM: 'clearing-realm-1und1',
    CLIENT_ID: 'clearing-gui-client',
    REDIRECT_URI: 'https://1und1.cp-gui.cp-dev.dev.bitconex.com/'
  }
};
